export const navbar_items = [
	{
		id: "01",
		title: "singles",
	},
	{
		id: "02",
		title: "remixes",
	},
	{
		id: "03",
		title: "albums",
	},
	{
		id: "04",
		title: "compilations",
	},
	{
		id: "04",
		title: "podcast",
	},
];
export const about_text = "Music serves as a universal language that connects people regardless of our background or beliefs and reminds us of our common humanity. The enigmatic nature of music lies in its ability to touch each person in a unique and personal way. It has the power to transport us to different times and places, evoking memories and associations that may have long been forgotten. It can unlock doors to emotions we never knew existed or provide solace in times of sadness and distress.";
