import {FiWifi} from "react-icons/fi";

export default function Connectionstatus(props) {
	return (
		<div className="text-center mt-5 pt-5">
			<FiWifi style={{fontSize: "25vw", color: "#d2d2d2"}} />
			<div className="mt-4">
				{"hmmm"}
				<br /> {"you are disconnected"}
			</div>
		</div>
	);
}
