import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {navbar_items} from "../static";
import {CiMenuFries} from "react-icons/ci";
import {FiX} from "react-icons/fi";
export default function Navbar() {
	const [menubar, setmenubar] = useState(false);
	const [navbarstate, setnavbarstate] = useState(false);

	useEffect(() => {
		const updatePosition = () => {
			setnavbarstate(window.pageYOffset);
		};
		window.addEventListener("scroll", updatePosition);
		updatePosition();
		return () => window.removeEventListener("scroll", updatePosition);
	}, [navbarstate]);

	const scrollToElement = id => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({behavior: "smooth"});
		}
	};

	return (
		<nav className={`navbar navbar-expand-lg position-fixed top-0 z-3 p-0 px-5 text-white w-100 py-3 ${menubar ? "" : navbarstate ? "blur mt-0" : "mt-5"}`}>
			<div className="container p-0">
				<Link to={window.origin} className="navbar-brand p-0 p-0" href="#">
					<div className="sub_title fs-3 text-white p-0">Andy</div>
					<div className="sub_title fs-3 text-white p-0">Schossow</div>
				</Link>

				<button className="btn p-0 fs-3 text-white border-0 hidden-lg hidden-md" type="button" onClick={e => setmenubar(true)}>
					<CiMenuFries />
				</button>

				<div className={`offcanvas offcanvas-end ${menubar ? "show" : ""}`}>
					<div className="offcanvas-body justify-content-end text-white">
						<ul className={`navbar-nav d-lg-none d-flex justify-content-center h-100`}>
							<button type="button" className="btn p-0 fs-1 mb-5 text-white border-0 hidden-lg hidden-md" onClick={e => setmenubar(false)}>
								<FiX />
							</button>

							{navbar_items.map((item, i) => (
								<li key={i}>
									<Link
										to={window.origin + "/#" + item.title}
										onClick={e => {
											scrollToElement(item.title);
											setmenubar(false);
										}}
										className="sub_title nav-link text-white text-uppercase mb-2"
									>
										{item.title}
									</Link>
								</li>
							))}
						</ul>
						<ul className="navbar-nav d-none d-lg-flex">
							{navbar_items.map((item, i) => (
								<li key={i} className="nav-item ms-3">
									<Link to={window.origin + "/#" + item.title} onClick={() => scrollToElement(item.title)} className="nav-link  text-white text-uppercase">
										{item.title}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}
