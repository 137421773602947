import {useState, useEffect} from "react";
import axios from "axios";
import {BASE, PODCASTS, RELEASES} from "../api";
import Banner from "./Banner";
import About from "./About";
import Releases from "./Releases";
import Podcasts from "./Podcasts";
import Contacts from "./Contacts";

export default function Layout() {
	const [state, setState] = useState({
		single: [],
		remix: [],
		upcoming: [],
		loading: true,
	});
	const [podcasts, setPodcasts] = useState({
		studio_album: [],
		studio_compilation: [],
		back_to_the_roots: [],
		future_sign: [],
		loading: true,
	});

	useEffect(() => {
		axios.all([axios.get(BASE + RELEASES), axios.get(BASE + PODCASTS)]).then(
			axios.spread((...responses) => {
				setState({
					single: responses[0].data
						.sort((a, b) => b.id - a.id)
						.filter(i => i.release_type === 1 && Date.now() >= new Date(i.date)),
					remix: responses[0].data
						.sort((a, b) => b.id - a.id)
						.filter(i => i.release_type === 2 && Date.now() >= new Date(i.date)),
					upcoming: responses[0].data
						.sort((a, b) => b.id - a.id)
						.filter(i => Date.now() < new Date(i.date)),
					loading: false,
				});
				setPodcasts({
					studio_album: responses[1].data.filter(
						i => i.release_type === 3 && Date.now() >= new Date(i.date)
					),
					studio_compilation: responses[1].data.filter(
						i => i.release_type === 4 && Date.now() >= new Date(i.date)
					),
					back_to_the_roots: responses[1].data.filter(
						i => i.release_type === 5 && Date.now() >= new Date(i.date)
					),
					future_sign: responses[1].data.filter(
						i => i.release_type === 6 && Date.now() >= new Date(i.date)
					),
					loading: false,
				});
			})
		);
	}, []);

	return (
		<div className="container p-0" style={{marginTop: "15vh"}}>
			<Banner />
			<About />
			<div id="singles">
				{state.upcoming.length > 0 && (
					<Releases block_title={"Comming soon"} data={state.upcoming} loading={state.loading} />
				)}
				{state.single.length > 0 && (
					<Releases block_title={"Singles"} data={state.single} loading={state.loading} />
				)}
			</div>
			<div id="remixes">
				{state.remix.length > 0 && (
					<Releases block_title={"Remixes"} data={state.remix} loading={podcasts.loading} />
				)}
			</div>
			<div id="albums">
				{podcasts.studio_album.length > 0 && (
					<Podcasts block_title={"Album"} data={podcasts.studio_album} loading={podcasts.loading} />
				)}
			</div>
			<div id="podcast">
				{podcasts.studio_compilation.length > 0 && (
					<Podcasts
						block_title={"Compilations"}
						data={podcasts.studio_compilation}
						loading={podcasts.loading}
					/>
				)}
			</div>
			<div id="podcast">
				{podcasts.future_sign.length > 0 && (
					<Podcasts
						block_title={"Future Sign"}
						data={podcasts.future_sign}
						loading={podcasts.loading}
					/>
				)}
			</div>
			<div id="podcast">
				{podcasts.back_to_the_roots.length > 0 && (
					<Podcasts
						block_title={"Back To The Roots"}
						data={podcasts.back_to_the_roots}
						loading={podcasts.loading}
					/>
				)}
			</div>
			<Contacts />
		</div>
	);
}
