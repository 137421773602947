import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BASE, RELEASES} from "../api";

import youtube_music from "../assets/icons/youtube_music.svg";
import yandex from "../assets/icons/yandex.svg";
import tidal from "../assets/icons/tidal.svg";
import spotify from "../assets/icons/spotify.svg";
import soundcloud from "../assets/icons/soundcloud.svg";
import junorecords from "../assets/icons/juno-download.svg";
import itunes from "../assets/icons/itunes.svg";
import deezer from "../assets/icons/deezer.svg";
import beatbort from "../assets/icons/beatport.svg";
import apple_music from "../assets/icons/apple-music.svg";
import amazon_music from "../assets/icons/amazon.svg";

export default function ReleaseItemModal() {
	const {id} = useParams();

	const [state, setState] = useState();

	useEffect(() => {
		axios.get(BASE + RELEASES + id + `/`).then(res => {
			setState(res.data);
		});
	}, []);

	return (
		<div className="py-5 my-5">
			{state ? (
				<div className="container py-5" style={{zIndex: 1}}>
					<div className="card fullview w-100 text-white p-3">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-12 overflow-hidden rounded">
								<img data-pr-tooltip="Logo" className="w-100" src={state?.cover} />
							</div>
							<div className="col-lg-8 col-md-8 col-12">
								<div className="d-grid mb-3 pb-3">
									<div className="fs-1 fw-bolder m-0 mb-3 pb-2">{state?.title}</div>
									{state?.date && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Release Date:</span>
											<span>{state?.date?.slice(0, 10).split("-").reverse().join("/")}</span>
										</div>
									)}
									{state?.label && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Label:</span>
											<span>{state?.label}</span>
										</div>
									)}
									{state?.key && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> KEY:</span>
											<span>{state?.key}</span>
										</div>
									)}
									{state?.bpm && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> BPM:</span>
											<span>{state?.bpm}</span>
										</div>
									)}
									{state?.duration && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Duration:</span>
											<span>{state?.duration}</span>
										</div>
									)}
								</div>

								<div className="d-grid">
									<div className=" col-12">
										{state?.spotify_url && (
											<a href={state?.spotify_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={spotify} alt={state?.title} />
											</a>
										)}
										{state?.beatbort_url && (
											<a href={state?.beatbort_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={beatbort} alt={state?.title} />
											</a>
										)}
										{state?.junorecords_url && (
											<a href={state?.junorecords_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={junorecords} alt={state?.title} />
											</a>
										)}
										{state?.apple_music_url && (
											<a href={state?.apple_music_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={apple_music} alt={state?.title} />
											</a>
										)}
										{state?.itunes_url && (
											<a href={state?.itunes_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={itunes} alt={state?.title} />
											</a>
										)}
										{state?.youtube_music_url && (
											<a href={state?.youtube_music_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={youtube_music} alt={state?.title} />
											</a>
										)}
										{state?.amazon_music_url && (
											<a href={state?.amazon_music_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={amazon_music} alt={state?.title} />
											</a>
										)}
										{state?.soundcloud_url && (
											<a href={state?.soundcloud_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={soundcloud} alt={state?.title} />
											</a>
										)}
										{state?.deezer_url && (
											<a href={state?.deezer_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={deezer} alt={state?.title} />
											</a>
										)}

										{state?.tidal_url && (
											<a href={state?.tidal_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={tidal} alt={state?.title} />
											</a>
										)}

										{state?.yandex_url && (
											<a href={state?.yandex_url} target="blank" className="music-store-icon" style={{float: "left"}}>
												<img src={yandex} alt={state?.title} />
											</a>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="shadow fullview py-5 my-5 show" style={{backgroundImage: "url(" + state?.cover + ")", opacity: 1}}></div>
					</div>
				</div>
			) : (
				<div className="d-flex justify-content-center align-items-center opacity-50" style={{height: "90vh"}}>
					<span className="loader"></span>
				</div>
			)}
		</div>
	);
}
