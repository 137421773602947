import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BASE, PODCASTS, RELEASES} from "../api";

import youtube_music from "../assets/icons/youtube_music.svg";
import yandex from "../assets/icons/yandex.svg";
import tidal from "../assets/icons/tidal.svg";
import spotify from "../assets/icons/spotify.svg";
import soundcloud from "../assets/icons/soundcloud.svg";
import pandora from "../assets/icons/pandora.svg";
import junorecords from "../assets/icons/juno-download.svg";
import itunes from "../assets/icons/itunes.svg";
import deezer from "../assets/icons/deezer.svg";
import beatbort from "../assets/icons/beatport.svg";
import awa_music from "../assets/icons/awa_music.svg";
import apple_music from "../assets/icons/apple-music.svg";
import amazon_music from "../assets/icons/amazon.svg";
import seven_digital from "../assets/icons/7digital.svg";

export default function Podcastsfullview() {
	const {id} = useParams();

	const [state, setState] = useState();

	useEffect(() => {
		axios.get(BASE + PODCASTS + id + `/`).then(res => {
			setState(res.data);
		});
	}, []);

	return (
		<div className="py-5 my-5">
			{state ? (
				<div className="container py-5" style={{zIndex: 1}}>
					<div className="card fullview w-100 text-white p-3">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-12 overflow-hidden rounded">
								<img className="w-100" src={state?.cover} />
							</div>
							<div className="col-lg-8 col-md-8 col-12">
								<div className="d-grid mb-3 pb-3">
									<div className="fs-1 fw-bolder m-0 mb-3 pb-2">{state?.title}</div>
									{state?.date && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Released:</span>
											<span>{state.date?.slice(0, 10).split("-").reverse().join("/")}</span>
										</div>
									)}
									{state?.label && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Label:</span>
											<span>{state?.label}</span>
										</div>
									)}
									{state?.key && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> KEY:</span>
											<span>{state?.key}</span>
										</div>
									)}
									{state?.bpm && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> BPM:</span>
											<span>{state?.bpm}</span>
										</div>
									)}
									{state?.duration && (
										<div className="d-flex">
											<span className="opacity-50 me-2"> Duration:</span>
											<span>{state?.duration}</span>
										</div>
									)}
								</div>

								<div className="d-grid">
									<div className="d-flex col-12">
										{state?.youtube_music_url && (
											<a href={state?.youtube_music_url} target="blank" className="music-store-icon">
												<img src={youtube_music} alt={state?.title} />
											</a>
										)}

										{state?.soundcloud_url && (
											<a href={state?.soundcloud_url} target="blank" className="music-store-icon">
												<img src={soundcloud} alt={state?.title} />
											</a>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="fullview w-100 text-white border-0 p-3 mt-5" style={{whiteSpace: "break-spaces"}}>
								{state?.playlist}
							</div>
						</div>
						<div className="shadow fullview py-5 my-5 show" style={{backgroundImage: "url(" + state?.cover + ")", opacity: 1}}></div>
					</div>
				</div>
			) : (
				<div className="d-flex justify-content-center align-items-center opacity-50" style={{height: "90vh"}}>
					<span className="loader"></span>
				</div>
			)}
		</div>
	);
}
