import {useState, useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import Navbar from "./comps/Navbar";
import Footer from "./comps/Footer";
import Releasesfullview from "./comps/Releasesfullview";
import Podcastsfullview from "./comps/Podcastsfullview";
import Layout from "./comps/Layout";
import Connectionstatus from "./comps/Connectionstatus";
import {useLocation} from "react-router-dom";

export default function App() {
	const {pathname} = useLocation();
	const [status, setStatus] = useState();
	useEffect(() => {
		if (navigator.onLine) {
			setStatus(true);
		} else {
			setStatus(false);
		}
	});
	window.addEventListener("offline", () => setStatus(false));
	window.addEventListener("online", () => setStatus(true));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className="d-flex flex-column min-vh-100">
			<div className="container-fluid mt-2 flex-grow-1">
				<Navbar />
				{status ? (
					<Routes>
						<Route exact path={"/"} element={<Layout />} />
						<Route exact path="/releases/:id" element={<Releasesfullview />} />
						<Route exact path="/podcasts/:id" element={<Podcastsfullview />} />
					</Routes>
				) : (
					<Connectionstatus />
				)}
			</div>
			<Footer />
		</div>
	);
}
