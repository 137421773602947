import {SiBeatport, SiApplemusic, SiSpotify, SiFacebook, SiInstagram, SiYoutube, SiSoundcloud} from "react-icons/si";

export default function Contacts() {
	return (
		<section className="row pt-5 pb-3 text-center align-items-center m-0">
			<div className="col-12">
				<div className="text-uppercase mt-5 head_line opacity-50">
					<div className="">Get in touch</div>
				</div>
				<div className="d-grid align-items-center mt-3">
					<span className="opacity-70 me-2">E-mail:</span>
					<span>
						<a target="blank" href="mailto:info@andyschossow.com" className="opacity-50 text-decoration-none text-white">
							info@andyschossow.com
						</a>
					</span>
				</div>
				<div className="d-grid align-items-center mt-3 contacts">
					<span className="opacity-70 me-2 mb-2">Follow on:</span>
					<span>
						<a target="blank" href="https://www.facebook.com/andy.schossow/" className="fb">
							<SiFacebook className="mx-3 fs-4" />
						</a>
						<a target="blank" href="https://www.instagram.com/andy.schossow/" className="ig">
							<SiInstagram className="mx-3 fs-4" />
						</a>
						<a target="blank" href="https://www.youtube.com/@andyschossow" className="yt">
							<SiYoutube className="mx-3 fs-3" />
						</a>
						<a target="blank" href="https://open.spotify.com/artist/6V9Y4IOtx33bdHxLadLXo9" className="sp">
							<SiSpotify className="mx-3 fs-4" />
						</a>
						<a target="blank" href="https://www.beatport.com/artist/andy-schossow/1098842" className="bp">
							<SiBeatport className="mx-3 fs-4" />
						</a>
						<a target="blank" href="https://music.apple.com/us/artist/andy-schossow/1649545061" className="am">
							<SiApplemusic className="mx-3 fs-4" />
						</a>
						<a target="blank" href="https://on.soundcloud.com/YxfFS" className="sc">
							<SiSoundcloud className="mx-3 fs-2" />
						</a>
					</span>
				</div>
			</div>
		</section>
	);
}
