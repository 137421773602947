import React from "react";

export default function Footer() {
	return (
		<section>
			<footer className="flex-shrink-0 footer p-3 mt-auto text-center text-white opacity-50">
				&copy; Andy Schossow 2022 -<span className="mx-1">{new Date().getFullYear()}</span> All rights reserved.
			</footer>
		</section>
	);
}
