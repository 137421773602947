import ReleaseItem from "./ReleaseItem";

export default function Releases(props) {
	return (
		<section className="row p-5">
			<div className="text-uppercase mt-5">
				<div className="sub_title">{props.block_title}</div>
			</div>
			{props?.data.map((item, index) => (
				<ReleaseItem key={index} item={item} loading={props.loading} index={index} />
			))}
		</section>
	);
}
