import React from "react";

export default function Banner() {
	return (
		<section className="w-100 p-5 welcome_wrapper">
			<div className="text-uppercase">
				<div className="head_line text-md-start text-center mb-0">Andy</div>
				<div className="head_line text-md-end text-center">Schossow</div>
			</div>
			<div className="text-uppercase mt-5">
				<div className="sub_title text-md-start text-center">Music</div>
				<div className="sub_title text-md-start text-center">is...</div>
			</div>
		</section>
	);
}
