import {Link} from "react-router-dom";

export default function ReleaseItem(props) {
	return props.item ? (
		<>
			<div key={props.index} className="col-md-4 col-sm-6 col-10 mx-auto mx-sm-0 stretch-card" data-aos="fade-up">
				<Link
					to={`/releases/${props.item.id}`}
					className="card w-100 bg-transparent border-0 text-white stretch-card text-decoration-none my-3"
					onContextMenu={e => {
						props.onRightClick(e, props.item.id);
						props.setSelectedItem(props.item);
					}}
				>
					<div className="awesome-shadow">
						<div className="shadow rounded" style={{backgroundImage: "url(" + props.item.cover + ")"}}></div>
						<div className="img rounded" style={{backgroundImage: "url(" + props.item.cover + ")"}}></div>
					</div>
					<div className="mt-3">
						{props?.item.date && (
							<div className="d-flex">
								<div className="me-3 opacity-50">Release Date:</div>
								<span>{props?.item?.date?.slice(0, 10).split("-").reverse().join("/")}</span>
							</div>
						)}

						{props?.item.label && (
							<div className="d-flex">
								<div className="me-3 opacity-50">Label:</div>
								<div className="text-white">{props.item.label}</div>
							</div>
						)}
						{props?.item.key && (
							<div className="d-flex">
								<div className="me-3 opacity-50">KEY:</div>
								<div className="text-white">{props.item.key}</div>
							</div>
						)}
						{props?.item.bpm && (
							<div className="d-flex">
								<div className="me-3 opacity-50">BPM:</div>
								<div className="text-white">{props.item.bpm}</div>
							</div>
						)}
						{props?.item.duration && (
							<div className="d-flex">
								<div className="me-3 opacity-50">Duration:</div>
								<div className="text-white">{props.item.duration}</div>
							</div>
						)}
					</div>
				</Link>
			</div>
		</>
	) : (
		"loading"
	);
}
