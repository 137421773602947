import PodcastItem from "./PodcastItem";

export default function Rodcasts(props) {
	return (
		<section className="row p-5">
			<div className="text-uppercase mt-5">
				<div className="sub_title">{props.block_title}</div>
			</div>
			{props?.data.map((item, index) => (
				<PodcastItem key={index} item={item} index={index} />
			))}
		</section>
	);
}
